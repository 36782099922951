import { OfertaType } from "../../types/OfertaType";
import { compareOffers } from "../../reducers/EvaluateOffer";
import {
  VALORACION_SIN_VALORAR,
  VALORACION_DESCARTADO,
  VALORACION_TELEFONICA,
  VALORACION_CVCLIENTE,
  VALORACION_CONTRATADO,
  VALORACION_PRESENCIAL,
  VALORACION_PRESENCIAL_CLIENTE,
} from "../defaults/valoracion";
import { TGetContractsCollectionItem } from "../../api/contract/contract.types";
import {
  CONTRACT_ORIGIN_CHANGE_EMPLOYER,
  CONTRACT_ORIGIN_WARRANTY_ZERO,
} from "../defaults/contrato";

export const getOfertasRecomendadas = (
  ofertas: OfertaType[],
  appliedOffers: any,
  authUser: any
): OfertaType[] => {
  let filteredOfertas: any = [];
  if (authUser) {
    filteredOfertas = ofertas.filter((item: any) => {
      // eslint-disable-next-line
      const found = appliedOffers.find((appliedOffer: any) => {
        if (appliedOffer.contratoId === item.id) return true;
      });
      return !found;
    });
  } else {
    filteredOfertas = ofertas;
  }

  const ofertasSimilares = filteredOfertas.sort(compareOffers).slice(0, 3);

  return ofertasSimilares;
};

export const decorateContratoWithNumbers = (contrato: any) => {
  if (!contrato.valoraciones) {
    return { ...contrato, valoraciones: [] };
  }

  let sv = 0;
  let descart = 0;
  let telf = 0;
  let pres = 0;
  let cv_cli = 0;
  let pres_cli = 0;
  let contr = 0;
  contrato.valoraciones.forEach((val: any) => {
    if (val.Estado_en_proceso === VALORACION_SIN_VALORAR) {
      sv++;
    }
    if (val.Estado_en_proceso === VALORACION_DESCARTADO) {
      descart++;
    }
    if (val.Estado_en_proceso === VALORACION_TELEFONICA) {
      telf++;
    }
    if (val.Estado_en_proceso === VALORACION_PRESENCIAL) {
      pres++;
    }
    if (val.Estado_en_proceso === VALORACION_CVCLIENTE) {
      cv_cli++;
    }
    if (val.Estado_en_proceso === VALORACION_PRESENCIAL_CLIENTE) {
      pres_cli++;
    }
    if (val.Estado_en_proceso === VALORACION_CONTRATADO) {
      contr++;
    }
  });
  contrato.numSinValorar = sv;
  contrato.numTelefonica = telf;
  contrato.numPresencial = pres;
  contrato.numDescartados = descart;
  contrato.numCVCliente = cv_cli;
  contrato.numPresencialCliente = pres_cli;
  contrato.numContratado = contr;
  contrato.numInscritos = sv + telf + pres + cv_cli + pres_cli + contr;

  return contrato;
};

export const decorateContratosWithNumbers = (contratosResponse: any) => {
  const contratos = contratosResponse.map((contrato: any) => {
    return decorateContratoWithNumbers(contrato);
  });

  return contratos;
};

export const isContractOriginWarrantyZeroOrChangeEmployer = (
  contract: TGetContractsCollectionItem
): boolean => {
  return (
    contract.Origen === CONTRACT_ORIGIN_WARRANTY_ZERO ||
    contract.Origen === CONTRACT_ORIGIN_CHANGE_EMPLOYER
  );
};
