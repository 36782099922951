import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../../../util/urlManager";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_STATUS, TEmployeeScore, TEmployeeTimeSlot,
} from "../../../../../../api/employee/employee.types";

const initialState = {
  search: "",
  titulacion: "",
  sexo: "",
  estadoCuideo: [
    EMPLOYEE_STATUS.ACTIVE,
    EMPLOYEE_STATUS.VALIDATED,
    EMPLOYEE_STATUS.VALIDATED_PLUS,
    EMPLOYEE_STATUS.NOT_VALIDATED,
  ],
  felizVitaStatus: [],
  businessCountry: "es",
  ciudad: "",
  provincia: "",
  department: "",
  carnet: "",
  vehiculo: "",
  lugarDeNacimiento: "",
  selectedEmployeeId: "",
  edad: [] as string[],
  experience: [] as string[],
  tipo: [] as string[],
  inOpenProcess: "no",
  inRecentProcesses: "",
  orden: "recientes" as "recientes" | "antiguos" | "activos",
  vista: "lista",
  "latitud[gt]": undefined,
  "latitud[lt]": undefined,
  "longitud[gt]": undefined,
  "longitud[lt]": undefined,
  x: undefined,
  y: undefined,
  z: undefined,
  enMapa: "no",
  tasks: [] as string[],
  languages: [] as string[],
  pathologies: [] as string[],
  interviewStatus: [] as string[],
  company: "",
  workRegime: [] as string[],
  page: 1,
  eTab: 0,
  ready: false,
  hasWhatsapp: "yes",
  botAvailable: "",
  availability: [
    EMPLOYEE_AVAILABILITY.AVAILABLE,
  ] as typeof EMPLOYEE_AVAILABILITY[keyof typeof EMPLOYEE_AVAILABILITY][],
  contractedFv: '0',
  hasTimeSlot: [] as TEmployeeTimeSlot[],
  hasEmployeeScore: [] as TEmployeeScore[],
};

export const resetState = {
  titulacion: "",
  sexo: "",
  estadoCuideo: [
    EMPLOYEE_STATUS.ACTIVE,
    EMPLOYEE_STATUS.VALIDATED,
    EMPLOYEE_STATUS.VALIDATED_PLUS,
    EMPLOYEE_STATUS.NOT_VALIDATED,
  ],
  felizVitaStatus: [],
  ciudad: "",
  provincia: "",
  department: "",
  carnet: "",
  vehiculo: "",
  lugarDeNacimiento: "",
  edad: [] as string[],
  experience: [] as string[],
  tipo: [] as string[],
  inOpenProcess: "no",
  inRecentProcesses: "",
  tasks: [] as string[],
  languages: [] as string[],
  pathologies: [] as string[],
  interviewStatus: [] as string[],
  company: "",
  workRegime: [] as string[],
  hasWhatsapp: "yes",
  botAvailable: "",
  availability: [
    EMPLOYEE_AVAILABILITY.AVAILABLE,
  ] as typeof EMPLOYEE_AVAILABILITY[keyof typeof EMPLOYEE_AVAILABILITY][],
  substitutions: "no",
  contractedFv: '0',
  hasTimeSlot: "",
  hasEmployeeScore: "",
};

export type TEmployeesPageFilters = typeof initialState;

export default function useEmployeesPageURLParams(
  searchString: string,
  history: any,
  location: any
) {
  const [filters, setFiltersState] = useState({ ...initialState });

  useEffect(() => {
    if (history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.page && typeof params.page !== "number") {
        params.page = parseInt(params.page);
      }
      if (params.eTab && typeof params.eTab !== "number") {
        params.eTab = parseInt(params.eTab);
      }
      Object.keys(params).forEach((key) => {
        if (
          Array.isArray((initialState as any)[key]) &&
          typeof params[key] === "string"
        ) {
          params[key] = [params[key]];
        }
      });

      setFiltersState((prevFilters) => {
        if (!_.some([prevFilters], params)) {
          return {
            ...prevFilters,
            ...params,
            ready: true,
          };
        } else {
          return {
            ...prevFilters,
            ready: true,
          };
        }
      });
    }
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TEmployeesPageFilters) => {
      const params = {
        ...newFilters,
        "latitud[gt]":
          newFilters.enMapa === "no" ? undefined : newFilters["latitud[gt]"],
        "latitud[lt]":
          newFilters.enMapa === "no" ? undefined : newFilters["latitud[lt]"],
        "longitud[gt]":
          newFilters.enMapa === "no" ? undefined : newFilters["longitud[gt]"],
        "longitud[lt]":
          newFilters.enMapa === "no" ? undefined : newFilters["longitud[lt]"],
        x: newFilters.enMapa === "no" ? undefined : newFilters.x,
        y: newFilters.enMapa === "no" ? undefined : newFilters.y,
        z: newFilters.enMapa === "no" ? undefined : newFilters.z,
      } as any;
      setFiltersState(newFilters);

      // Parse a bit wich parameters to set on url
      delete params.ready;
      delete params["longitud[gt]"];
      delete params["longitud[lt]"];
      delete params["latitud[gt]"];
      delete params["latitud[lt]"];

      // Clean not needed parameters
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return { filters, setFilters };
}
