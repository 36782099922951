import {
  COORDINATION_GROUP,
  TCoordinationGroup,
} from "../../../../../../../api/user/apiListStaffUsers";
import { TUserGroupAssignations } from "../../../../recruitersManager/types/UserGroupAssignations.types";
import { newStaffAssignationsContractStats } from "./StaffAssignationContractStats";
import { TStaffAssignationsCoordinator } from "./StaffAssignationsCoordinator";
import { newStaffAssignationServiceStats } from "./StaffAssignationServiceStats";

export const initGroups = (): TUserGroupAssignations[] => {
  const newGroups: TUserGroupAssignations[] = [];

  newGroups.push({
    id: COORDINATION_GROUP.BCN,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.MAD,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  /* newGroups.push({
    id: COORDINATION_GROUP.CAV,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  }); */
  /* newGroups.push({
    id: COORDINATION_GROUP.OTH_FAST,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  }); */
  /* newGroups.push({
    id: COORDINATION_GROUP.OTH_SLOW,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  }); */

  newGroups.push({
    id: COORDINATION_GROUP.NORTH_ZONE,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });

  newGroups.push({
    id: COORDINATION_GROUP.SOUTH_ZONE,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });

  newGroups.push({
    id: COORDINATION_GROUP.SAD,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.OTHER,
    serviceStats: newStaffAssignationServiceStats(),
    contractStats: newStaffAssignationsContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });

  return newGroups;
};

export const validGroupForCoordinator = (
  coordinator: TStaffAssignationsCoordinator
): TCoordinationGroup => {
  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.MAD)
  ) {
    return COORDINATION_GROUP.MAD;
  }
  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.BCN)
  ) {
    return COORDINATION_GROUP.BCN;
  }
  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.SAD)
  ) {
    return COORDINATION_GROUP.SAD;
  }
  /* if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.CAV)
  ) {
    return COORDINATION_GROUP.CAV;
  } */
  /*  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.OTH_FAST)
  ) {
    return COORDINATION_GROUP.OTH_FAST;
  } */
  /* if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.OTH_SLOW)
  ) {
    return COORDINATION_GROUP.OTH_SLOW;
  } */

  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.NORTH_ZONE)
  ) {
    return COORDINATION_GROUP.NORTH_ZONE;
  }

  if (
    coordinator.coordinationGroups &&
    coordinator.coordinationGroups.includes(COORDINATION_GROUP.SOUTH_ZONE)
  ) {
    return COORDINATION_GROUP.SOUTH_ZONE;
  }

  return COORDINATION_GROUP.OTHER;
};

export const groupName = (id: TCoordinationGroup): string => {
  if (id === COORDINATION_GROUP.BCN) {
    return "Barcelona";
  }
  if (id === COORDINATION_GROUP.MAD) {
    return "Madrid";
  }
  if (id === COORDINATION_GROUP.SAD) {
    return "SAD";
  }
  /* if (id === COORDINATION_GROUP.CAV) {
    return "País vasco";
  } */
  /* if (id === COORDINATION_GROUP.OTH_FAST) {
    return "Otros rápido";
  } */
  /* if (id === COORDINATION_GROUP.OTH_SLOW) {
    return "Otros difícil";
  } */

  if (id === COORDINATION_GROUP.NORTH_ZONE) {
    return "Zona Norte";
  }

  if (id === COORDINATION_GROUP.SOUTH_ZONE) {
    return "Zona Sur";
  }

  return "Sin asignar";
};
