import { useCallback, useEffect, useState } from "react";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../util/urlManager";
import _ from "lodash";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_STATUS,
} from "../../../../api/employee/employee.types";
import { CONTRACT_CANDIDATE_SORT_ORDER, TContractCandidateSortOrder } from "../../../../api/contract/contract.types";
import { TValuationStatus, VALUATION_STATUS } from "../../../../api/valuation/valuation.types";

export const initialState = {
  search: "",
  titulacion: "",
  sexo: "",
  estado: [
    EMPLOYEE_STATUS.NOT_VALIDATED,
    EMPLOYEE_STATUS.ACTIVE,
    EMPLOYEE_STATUS.VALIDATED,
    EMPLOYEE_STATUS.VALIDATED_PLUS,
  ],
  felizVitaStatus: [] as string[],
  ciudad: "",
  origen: "" as "" | "web" | "cuideo",
  provincia: "",
  lugarDeNacimiento: "",
  carnet: "",
  vehiculo: "",
  edad: [] as string[],
  experience: [] as string[],
  tasks: [] as string[],
  languages: [] as string[],
  pathologies: [] as string[],
  inOpenProcess: "",
  botAvailable: "",
  inRecentProcesses: "",
  tipo: "",
  orden: CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED as TContractCandidateSortOrder,
  vista: "lista",
  estadoEnProceso: VALUATION_STATUS.NOT_VALUED as TValuationStatus,
  selectedValuationId: "",
  interviewStatus: [] as string[],
  company: "",
  workRegime: [] as string[],
  eTab: 0,
  page: 1,
  ready: false,
  availability: [
    EMPLOYEE_AVAILABILITY.AVAILABLE,
  ] as typeof EMPLOYEE_AVAILABILITY[keyof typeof EMPLOYEE_AVAILABILITY][],
  applyByGoalDate: "",
  substitutions: "",
  hasTimeSlot: "",
  hasEmployeeScore: "",
  favorite: "1",
};

export const resetStateSuggestions = {
  ...initialState,
  estado: [
    EMPLOYEE_STATUS.NOT_VALIDATED,
    EMPLOYEE_STATUS.VALIDATED,
    EMPLOYEE_STATUS.VALIDATED_PLUS,
  ],
  botAvailable: "yes",
  inOpenProcess: "no",
  workRegime: [] as string[],
};

export type TContractPageFilters = typeof initialState;

export default function useContractPageUrlParams(history: any) {
  const [filters, setFiltersState] = useState({ ...initialState });

  useEffect(() => {
    if (!filters.ready || history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.page && typeof params.page !== "number") {
        params.page = parseInt(params.page);
      }
      if (params.eTab && typeof params.eTab !== "number") {
        params.eTab = parseInt(params.eTab);
      }
      Object.keys(params).forEach((key) => {
        if (
          Array.isArray((initialState as any)[key]) &&
          typeof params[key] === "string"
        ) {
          params[key] = [params[key]];
        }
      });

      setFiltersState((prevFilters) => {
        if (!_.some([prevFilters], params)) {
          return {
            ...prevFilters,
            ...params,
            ready: true,
          };
        } else {
          return {
            ...prevFilters,
            ready: true,
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TContractPageFilters) => {
      const params = {
        ...newFilters,
      } as any;
      setFiltersState(newFilters);

      delete params.ready;

      // Clean not needed parameters
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return {
    filters,
    setFilters,
  };
}
