import { Grid, Grow, Typography } from "@material-ui/core";
import classNames from "classnames";
import { motion, Variants } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import {
  EMPLOYEE_STATUS,
  TGetEmployeesCollectionItem,
} from "../../../../../../api/employee/employee.types";
import CuideoUserAvatar from "../../../../../../components/CuideoUserAvatar";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { TOnCandidateSelectFn } from "../../types/candidate.types";
import { candidateIsFavoriteOfUser } from "../../util/candidate.util";
import CandidateStatusTag from "../CandidateStatusTag/CandidateStatusTag";
import CandidateFvStatusTag from "../CandidateFvStatusTag/CandidateFvStatusTag";

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.85;
  transform: translate(-50%, -50%);

  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }

  .CMuiCuideoUserAvatar-felizVitaLogo {
    padding: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
  }

  &.CMuiCandidateMarker-favorite {
    z-index: 110;
  }

  &.CMuiCandidateMarker-hovered {
    opacity: 1;
    z-index: 111;
    .MuiAvatar-root {
      width: 3.5rem;
      height: 3.5rem;
    }
    .CMuiCuideoUserAvatar-felizVitaLogo {
      width: 1.6125rem;
      height: 1.6125rem;
    }
  }
`;

const StyledColorIndicator = styled.div`
  display: block;
`;

const StyledAvatarWrapper = styled.div`
  display: block;
  border-radius: 50%;
  border: 0.4375rem solid #004b7b;
  border-color: ${cuideoTheme.palette.primary.main};

  &.CMuiCandidateMarker-active {
    border-color: green;
  }

  &.CMuiCandidateMarker-plus-validated {
    border-color: #ebc200;
  }

  &.CMuiCandidateMarker-discarded {
    border-color: orange;
  }

  &.CMuiCandidateMarker-not-validated {
    border-color: ${cuideoTheme.palette.primary.light};
  }

  &.CMuiCandidateMarker-favorite {
    border-color: ${COLOR_RED_NOTIFICATION};
  }
`;

const StyledPopupContainer = styled.div`
  position: relative;
`;

const StyledPaperWrapper = styled.div<{ hovered?: string }>`
  padding: 0;
  position: absolute;
  bottom: 4.5rem;
  left: -4.625rem;
  z-index: 10;
  width: 12rem;
  ${({ hovered }) => {
    if (hovered) {
      return css`
        bottom: 5.25rem;
        left: -3.875rem;
      `;
    }
  }}
`;
const StyledPaper = styled.div`
  position: relative;
  background: ${cuideoTheme.palette.common.white};
  padding: 0.5rem;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  :after,
  :before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: ${cuideoTheme.palette.common.white};
    border-width: 0.75rem;
    margin-left: -0.75rem;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: ${cuideoTheme.palette.common.white};
    border-width: 0.75rem;
    margin-left: -0.75rem;
  }
`;
const StyledCandidateCode = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.15;
  font-weight: 400;
  margin-bottom: 0.25rem;
`;
const StyledCandidateName = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.15;
`;
const StyledPopupAvatarWrapper = styled.div`
  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledCandidateStatuses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.25rem;
`;

const StyledCandidatoEstado = styled.div``;

const variants: Variants = {
  enter: (custom: number) => ({
    opacity: 1,
    transition: { delay: custom / 1000 },
  }),
  exit: {
    opacity: 0,
  },
};

interface ICandidateMarkerProps {
  candidate: TGetEmployeesCollectionItem;
  isHovered?: boolean;
  // isOpen?: boolean;
  appearDelay?: number;
  onSelect: TOnCandidateSelectFn;
  onMouseEnter: () => void;
  onMouseLeave: () => void;

  // Inherited from GoogleMap component
  lat: number;
  lng: number;
}

const CandidateMarker: React.FC<ICandidateMarkerProps> = (props) => {
  const {
    candidate,
    isHovered,
    appearDelay,
    onSelect,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    onSelect(candidate);
  };

  const candidateIsFavorite = candidateIsFavoriteOfUser(
    candidate,
  );

  return (
    <motion.div
      custom={appearDelay}
      initial="exit"
      animate="enter"
      variants={variants}
    >
      <StyledWrapper
        onClick={handleClick}
        className={classNames({
          "CMuiCandidateMarker-hovered": isHovered,
          "CMuiCandidateMarker-favorite": candidateIsFavorite,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledColorIndicator>
          <StyledAvatarWrapper
            className={classNames({
              "CMuiCandidateMarker-validated":
                candidate.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED,
              "CMuiCandidateMarker-active":
                candidate.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE,
              "CMuiCandidateMarker-plus-validated":
                candidate.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED_PLUS,
              "CMuiCandidateMarker-discarded":
                candidate.Estado_Cuideo === EMPLOYEE_STATUS.DISCARDED,
              "CMuiCandidateMarker-not-validated":
                candidate.Estado_Cuideo === EMPLOYEE_STATUS.NOT_VALIDATED,
              "CMuiCandidateMarker-favorite": candidateIsFavorite,
            })}
          >
            <CuideoUserAvatar
              user={{
                userFullName: "",
                userAvatar: candidate.avatarUrl,
              }}
              isFelizVita={candidate.Company === "FV"}
            />
          </StyledAvatarWrapper>
        </StyledColorIndicator>
        <StyledPopupContainer>
          <Grow in={isHovered}>
            <StyledPaperWrapper hovered={isHovered ? "hovered" : undefined}>
              <StyledPaper>
                <Grid container spacing={4} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <StyledPopupAvatarWrapper>
                      <CuideoUserAvatar
                        user={{
                          userFullName: "",
                          userAvatar: candidate.avatarUrl,
                        }}
                        isFelizVita={candidate.Company === "FV"}
                      />
                    </StyledPopupAvatarWrapper>
                  </Grid>
                  <Grid item>
                    <StyledCandidateName>{`${candidate.Nombre} ${candidate.Apellidos}`}</StyledCandidateName>
                    <StyledCandidateCode>{candidate.Name}</StyledCandidateCode>
                    <StyledCandidateStatuses>
                      <StyledCandidatoEstado>
                        <CandidateStatusTag
                          candidateId={candidate.id}
                          status={candidate.Estado_Cuideo}
                        />
                      </StyledCandidatoEstado>
                      {!!candidate.Estado_FV && (
                        <StyledCandidatoEstado>
                          <CandidateFvStatusTag status={candidate.Estado_FV} />
                        </StyledCandidatoEstado>
                      )}
                    </StyledCandidateStatuses>
                  </Grid>
                </Grid>
              </StyledPaper>
            </StyledPaperWrapper>
          </Grow>
        </StyledPopupContainer>
      </StyledWrapper>
    </motion.div>
  );
};

export default React.memo(
  (props: ICandidateMarkerProps) => {
    return <CandidateMarker {...props} />;
  },
  (prevProps, nextProps) => {
    if (prevProps.isHovered !== nextProps.isHovered) {
      return false;
    }

    return true;
  }
);
