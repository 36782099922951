import {
  FormControl,
  InputLabel,
  MenuItem,
  Select, Typography
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  EMPLOYEE_FELIZ_VITA_STATUS,
  EMPLOYEE_STATUS
} from "../../../../../../api/employee/employee.types";
import useFormatters from "../../../../../../components/FormattedFields/useFormatters";
import FelizVitaLogo from "../../../../../../assets/images/feliz_vita_logo.svg";

const StyledFormControl = styled(FormControl)<{ status: string }>`
  .MuiFilledInput-root {
    background-color: ${cuideoTheme.palette.primary.light};
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    .MuiSelect-select {
      padding: 0.625rem 0.75rem 0;
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .MuiFormLabel-root {
    color: #ddd;
    font-size: 0.875rem;
  }
  .MuiSelect-select {
    color: ${cuideoTheme.palette.common.white};
    font-weight: 700;
    font-size: 1.25rem;
    min-width: 6rem;

    &:before {
      display: none;
    }
  }
  .MuiSvgIcon-root {
    display: none;
  }

  ${({ status }) => {
    switch (status) {
      case EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED:
        return `
        .MuiFilledInput-root {
          background-color: orange;
        }
        .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
          color: ${cuideoTheme.palette.primary.main};
        }
        `;
      case EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED:
        return `
          .MuiFilledInput-root {
            background-color: ${cuideoTheme.palette.grey[300]};
          }
          .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
            color: ${cuideoTheme.palette.primary.main};
          }
            `;
      case EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS:
        return `
            .MuiFilledInput-root {
              background-color: #ebc200;
            }
            .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
              color:  ${cuideoTheme.palette.primary.main};
            }
          `;
      case EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE:
        return `
            .MuiFilledInput-root {
              background-color: green;
            }
            .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
              color: ${cuideoTheme.palette.common.white};
            }
          `;
      default:
        return `
          .MuiFilledInput-root {
            background-color: ${cuideoTheme.palette.primary.light};
          }
          .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
            color: ${cuideoTheme.palette.primary.contrastText};
          }
        `;
    }
  }}
`;

const StyledFelizVitaLogoWrapper = styled.div`
  background-color: #fff;
  padding: 0.25rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -0.125rem;
  bottom: 0.375rem;
  z-index: 1;
`;

const StyledFelizVitaLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledWrapper = styled.div`
  position: relative;
  padding-left: 0.625rem;
`;

const StyledContractedFv = styled.div`
  position: absolute;
  top: -10px;
  right: -40px;
  z-index: 10;
  color: ${cuideoTheme.palette.common.white};
  background-color: ${cuideoTheme.palette.success.dark};
  padding: 0 0.5rem;
  border-radius: 0.25rem;
`;

interface ICandidateFvStatusProps {
  status: string;
  contractedFv: boolean
}

const CandidateFvStatus: React.FC<ICandidateFvStatusProps> = ({ status, contractedFv }) => {
  const { formatEmployeeStatus } = useFormatters();

  return (
    <StyledWrapper>
      <StyledFelizVitaLogoWrapper className="CMuiCuideoUserAvatar-felizVitaLogo">
        <StyledFelizVitaLogo src={FelizVitaLogo} />
      </StyledFelizVitaLogoWrapper>
      <StyledFormControl variant="filled" fullWidth status={status}>
        {(contractedFv && status === EMPLOYEE_STATUS.VALIDATED_PLUS) && (
          <StyledContractedFv>
            <Typography variant="caption">
              <FormattedMessage
                id="CandidateFvStatus.Contratado FV"
                defaultMessage="contratado"
              />
            </Typography>
          </StyledContractedFv>
        )}
        <InputLabel id="felizvita-status">
          <FormattedMessage
            id="CandidateFvStatus.Estado FelizVita"
            defaultMessage="Estado FelizVita"
          />
        </InputLabel>
        <Select id="felizvita-status" value={status} variant="filled" disabled>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED)}
          </MenuItem>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED)}
          </MenuItem>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS)}
          </MenuItem>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS)}
          </MenuItem>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE)}
          </MenuItem>
          <MenuItem value={EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED}>
            {formatEmployeeStatus(EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED)}
          </MenuItem>
        </Select>
      </StyledFormControl>
    </StyledWrapper>
  );
};

export default CandidateFvStatus;
