export enum ESquad {
  BARCELONA = "Barcelona",
  MADRID = "Madrid",
  EUSKADI = "País Vasco",
  OTHER_FAST = "Otros rápidos",
  OTHER_DIFFICULT = "Otros difícil",
  SOUTH_ZONE = "Zona Sur",
  NORTH_ZONE = "Zona Norte",
}

export const SQUADS_ABLE_TO_RUN_BOT = [
  ESquad.EUSKADI,
  ESquad.MADRID,
  ESquad.BARCELONA,
  ESquad.OTHER_DIFFICULT,
  ESquad.OTHER_FAST,
  ESquad.SOUTH_ZONE,
  ESquad.NORTH_ZONE,
];
