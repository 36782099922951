import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Form, getIn, useFormikContext } from "formik";
import { motion } from "framer-motion";
import { FormattedMessage, useIntl } from "react-intl";
import { string } from "yup";
import ManIcon from "../../../../../assets/images/iconos/man.svg";
import NoIcon from "../../../../../assets/images/iconos/no.svg";
import SiIcon from "../../../../../assets/images/iconos/si.svg";
import WomanIcon from "../../../../../assets/images/iconos/woman.svg";
import ChangeDNIButton, {
  IEmployeeChangeDNIPayload,
} from "../../../../../components/Candidato/ChangeDNIButton/ChangeDNIButton";
import NotesV2Table from "../../../../../components/Candidato/NotesV2Table/NotesV2Table";
import FormattedPathologyField from "../../../../../components/FormattedFields/FormattedPathologyField";
import FormattedTaskField from "../../../../../components/FormattedFields/FormattedTaskField";
import {
  FormikAddressField,
  FormikCheckbox,
  FormikRadioGroup,
  FormikSelect,
  FormikTextField,
} from "../../../../../components/FormikFields";
import FormikAttachCif from "../../../../../components/FormikFields/FormikAttachCif";
import FormikCVUpload from "../../../../../components/FormikFields/FormikCVUpload";
import FormikDatePicker from "../../../../../components/FormikFields/FormikDatePicker";
import VisibleFieldOnProfile from "../../../../../components/FormikFields/VisibleFieldOnProfile";
import {
  validateEmail,
  validatePhone,
  validateUniqueEmail,
  validateUniquePhone,
} from "../../../../../util/validators/yupValidators";
import { employeeIdExpiryIsValid } from "../../../../affinity/models/Employee/Employee";
import ExperiencesTable from "../../ExperiencesTable/ExperiencesTable";
import FormationsTable from "../../FormationsTable/FormationsTable";
import CommunicationsSection from "../CommunicationsSection";
import {
  profileVariants,
  StyledAdvancedDetailsContainer,
  StyledCheckCircleIcon,
  StyledExpiryAlert,
  StyledField,
  StyledGroup,
  StyledHeaderWithButton,
  StyledHelper,
  StyledIdExpiryWrapper,
  StyledLabel,
  StyledPhoneWrapper,
  StyledSectionHeader,
  StyledTopSaveButton,
  StyledWrapper,
} from "../CoordinatorFileSC";
import PhoneWaStatusTag from "../PhoneWaStatusTag";
import UserInfoBox from "../UserInfoBox";
import useCoordinatorFileESFormContext from "./hooks/useCoordinatorFileESFormContext";
import { TCoordinatorFileESOptions } from "./hooks/useCoordinatorFileESFormOptions";
import SadWorkerTag from "./SadWorkerTag";
import { Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";
import { cuideoTheme } from "../../../../../containers/themes/defaultTheme";

interface ICoordinatorFileInnerFormProps {
  employee: any;
  isUpdating: boolean;
  formOptions: TCoordinatorFileESOptions;
  onPartialUpdate: (fieldName: string, data: any) => void;
  setUpdating: (isUpdating: boolean) => void;
}

const CoordinatorFileInnerForm = (props: ICoordinatorFileInnerFormProps) => {
  const { employee, isUpdating, formOptions, onPartialUpdate } = props;
  const { formatMessage } = useIntl();
  const { formikContext, cityOptions, timeSlotOptions, formActions } =
    useCoordinatorFileESFormContext();

  const { setFieldValue } = useFormikContext();

  const [displayWorkingUntil, setDisplayWorkingUntil] = useState<boolean>(
    employee.Trabajando_Hasta !== undefined
  );

  const [showTimeSlotsOptions, setShowTimeSlotsOptions] =
    useState<boolean>(false);

  useEffect(() => {
    setShowTimeSlotsOptions(
      getIn(formikContext.values, "Externa_entre_semana") ||
        getIn(formikContext.values, "Externa_fin_de_semana")
    );
  }, [formikContext]);

  const handlePartialFormUpdate = (fieldName: string, data: any) => {
    formikContext.setFieldValue(fieldName, data);
    onPartialUpdate(fieldName, data);
  };

  const validatePhoneField = async (value: string) => {
    return new Promise((resolve) => {
      let syncError;
      try {
        string()
          .required(
            formatMessage({
              id: "CoordinatorFile.El teléfono es obligatorio",
              defaultMessage: "El teléfono es obligatorio",
            })
          )
          .test(
            "valid-phone",
            formatMessage({
              id: "CoordinatorFile.El teléfono debe ser válido",
              defaultMessage: "El teléfono debe ser válido",
            }),
            validatePhone
          )
          .validateSync(value);
      } catch (e) {
        syncError = e;
      }
      if (syncError) {
        return resolve(syncError);
      }
      // As this is defined here it can access props of the previous
      // state, so we can compare if there is any difference
      if (formikContext.values.Phone === value) {
        // Return same error
        return resolve(
          formikContext.errors.Phone
            ? { message: formikContext.errors.Phone }
            : null
        );
      }
      validateUniquePhone(value, employee.id).then((ret: any) => {
        if (!ret.unique) {
          resolve({
            message: formatMessage(
              {
                id: "CoordinatorFile.El teléfono ya está en uso por {Name}",
                defaultMessage: "El teléfono ya está en uso por {Name}",
              },
              {
                Name: ret.Name,
              }
            ),
          });
        }
        resolve([]);
      });
    }).then((errors: any) => {
      if (errors && errors.message) {
        return errors.message;
      }
    });
  };

  const validateEmailField = async (value: string) => {
    return new Promise((resolve) => {
      if (!value) {
        // Accept empty values (email can be empty)
        resolve([]);
      }
      let syncError;
      try {
        string()
          .test(
            "valid-email",
            formatMessage({
              id: "CoordinatorFile.El correo electrónico debe ser válido",
              defaultMessage: "El correo electrónico debe ser válido",
            }),
            validateEmail
          )
          .validateSync(value);
      } catch (e) {
        syncError = e;
      }
      if (syncError) {
        return resolve(syncError);
      }
      if (formikContext.values.Email === value) {
        return resolve(
          formikContext.errors.Email
            ? { message: formikContext.errors.Email }
            : null
        );
      }
      validateUniqueEmail(value, employee.id).then((ret: any) => {
        if (!ret.unique) {
          resolve({
            message: formatMessage(
              {
                id: "CoordinatorFile.El correo electrónico ya está en uso por {Name}",
                defaultMessage:
                  "El correo electrónico ya está en uso por {Name}",
              },
              {
                Name: ret.Name,
              }
            ),
          });
        }
        resolve([]);
      });
    }).then((errors: any) => {
      if (errors && errors.message) {
        return errors.message;
      }
    });
  };

  const handleDniChangeSuccess = (payload: IEmployeeChangeDNIPayload) => {
    formActions.dniChangeSuccess(payload);
    onPartialUpdate("DNI", payload.DNI_NIE);
  };

  const idExpiryWarning = !employeeIdExpiryIsValid(employee);

  const expiryWrapperClassName = idExpiryWarning ? "CMui-invalid" : undefined;

  return (
    <Form>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <>
          <StyledWrapper>
            <motion.div
              initial="exit"
              animate="enter"
              variants={profileVariants}
            >
              <Box width="100%">
                <StyledGroup>
                  <StyledHeaderWithButton>
                    <StyledSectionHeader variant="h4">
                      <FormattedMessage
                        id="CoordinatorFile.PersonalData"
                        defaultMessage="Datos Personales"
                      />
                    </StyledSectionHeader>
                    <StyledTopSaveButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={
                        !formikContext.dirty ||
                        !formikContext.isValid ||
                        formikContext.isSubmitting ||
                        isUpdating
                      }
                    >
                      {isUpdating ? (
                        <CircularProgress size={20} thickness={5} />
                      ) : (
                        <FormattedMessage
                          id="CoordinatorFile.Guardar cambios"
                          defaultMessage="Guardar cambios"
                        />
                      )}
                    </StyledTopSaveButton>
                  </StyledHeaderWithButton>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={4}>
                      <FormikTextField
                        name="Nombre"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Nombre"
                            defaultMessage="Nombre"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        fullWidth
                        required
                        uppercase
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormikTextField
                        name="Apellidos"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Surname1"
                            defaultMessage="Primer Apellido"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        fullWidth
                        required
                        uppercase
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormikTextField
                        name="Apellidos_2"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Surname2"
                            defaultMessage="Segundo Apellido"
                          />
                        }
                        fullWidth
                        uppercase
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={7}>
                      <FormikDatePicker
                        name="Fecha_de_nacimiento"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Fecha de nacimiento"
                            defaultMessage="Fecha de nacimiento"
                          />
                        }
                        fullWidth
                        margin="nomargin"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <FormikSelect
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Lugar de nacimiento"
                            defaultMessage="Lugar de nacimiento"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        name="Lugar_de_nacimiento"
                        required
                        variant="compact"
                        size="small"
                        margin="nomargin"
                        closeMenuOnSelect={true}
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Lugar de nacimiento",
                          defaultMessage: "Lugar de nacimiento",
                        })}
                        options={formOptions.countryOptions}
                      />
                    </Grid>
                  </Grid>
                </StyledGroup>
                <StyledGroup>
                  <Grid container spacing={10} justifyContent="space-between">
                    <Grid item xs={12} md={6} xl={4}>
                      <StyledAdvancedDetailsContainer>
                        <Grid container spacing={8}>
                          {employee.avatarUrl && (
                            <Grid item xs={12}>
                              <FormikCheckbox
                                label={
                                  <FormattedMessage
                                    id="CoordinatorFile.Ocultar imagen en perfil público"
                                    defaultMessage="Ocultar imagen en perfil público"
                                  />
                                }
                                name="No_mostrar_imagen"
                                variant="compact"
                              />
                            </Grid>
                          )}
                          {employee.Trabajador_SAD && <SadWorkerTag />}
                        </Grid>
                      </StyledAdvancedDetailsContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CommunicationsSection />
                    </Grid>
                  </Grid>
                </StyledGroup>

                <StyledGroup>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={5}>
                      <FormikAddressField
                        name="Direccion"
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Dirección",
                          defaultMessage: "Dirección",
                        })}
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Dirección"
                            defaultMessage="Dirección"
                          />
                        }
                        size="small"
                        margin="nomargin"
                        fullWidth
                        value={formikContext.values.Direccion}
                        handleChange={formActions.changeAddressStreet}
                        handleSelect={formActions.selectAddress}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormikTextField
                        name="Numero"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Número"
                            defaultMessage="Número"
                          />
                        }
                        fullWidth
                        margin="nomargin"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormikTextField
                        name="Piso"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Piso"
                            defaultMessage="Piso"
                          />
                        }
                        fullWidth
                        margin="nomargin"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormikTextField
                        name="Codigo_postal"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Código Postal"
                            defaultMessage="Código Postal"
                          />
                        }
                        fullWidth
                        margin="nomargin"
                        type="postalCode"
                        onChange={formActions.postalCodeChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                      <FormikSelect
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Provincia"
                            defaultMessage="Provincia"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        name="Provincia"
                        variant="compact"
                        size="small"
                        margin="nomargin"
                        closeMenuOnSelect={true}
                        isSearchable={false}
                        options={formOptions.provinceOptions}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormikSelect
                        name="Ciudad"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Ciudad"
                            defaultMessage="Ciudad"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        variant="compact"
                        size="small"
                        margin="nomargin"
                        options={cityOptions}
                        closeMenuOnSelect={true}
                        disabled={cityOptions.length < 2}
                        isSearchable={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                      <StyledPhoneWrapper>
                        <PhoneWaStatusTag waStatus={employee.phoneWaStatus} />
                        <FormikTextField
                          name="Phone"
                          label={
                            <FormattedMessage
                              id="CoordinatorFile.Teléfono"
                              defaultMessage="Teléfono"
                            />
                          }
                          fullWidth
                          telformat
                          validate={validatePhoneField}
                        />
                        <FormikCheckbox
                          label={
                            <FormattedMessage
                              id="CoordinatorFile.Tiene whatsapp"
                              defaultMessage="Tiene whatsapp"
                            />
                          }
                          name="hasWhatsapp"
                          variant="compact"
                        />
                      </StyledPhoneWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikTextField
                        name="Email"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Email"
                            defaultMessage="Correo electrónico"
                          />
                        }
                        fullWidth
                        validate={validateEmailField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8} alignItems="flex-start">
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={8} alignItems="flex-end">
                        <Grid item xs={12} md={8}>
                          <FormikTextField
                            name="DNI_NIE"
                            label={
                              <FormattedMessage
                                id="CoordinatorFile.DNI"
                                defaultMessage="DNI/NIE"
                              />
                            }
                            fullWidth
                            margin="nomargin"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ChangeDNIButton
                            employee={{
                              id: employee.id,
                              DNI_NIE: formikContext.values.DNI_NIE,
                            }}
                            onChangeError={formActions.dniChangeError}
                            onChangeStart={formActions.dniChangeStart}
                            onChangeSuccess={handleDniChangeSuccess}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledIdExpiryWrapper
                            className={expiryWrapperClassName}
                          >
                            {idExpiryWarning && (
                              <StyledExpiryAlert severity="warning">
                                <FormattedMessage
                                  id="CoordinatorFile.La fecha de vencimiento de DNI/NIE no es válida. Actualizala con un valor válido para poder avanzar valoraciones más allá de Telefónica"
                                  defaultMessage="La fecha de vencimiento de DNI/NIE no es válida. Actualizala con un valor válido para poder avanzar valoraciones más allá de Telefónica"
                                />
                              </StyledExpiryAlert>
                            )}
                            <FormikDatePicker
                              name="Vencimiento_NIE"
                              label={
                                <FormattedMessage
                                  id="CoordinatorFile.Fecha de vencimiento del DNI/NIE"
                                  defaultMessage="Fecha de vencimiento del DNI/NIE"
                                />
                              }
                              fullWidth
                              margin="nomargin"
                              size="small"
                            />
                          </StyledIdExpiryWrapper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box style={{ marginTop: "0.75rem" }}>
                        <FormikAttachCif
                          obverseFieldName="cif_obverse"
                          backFieldName="cif_back"
                          messages={{
                            label: "DNI/NIE",
                            addCifLabel: "Adjunta un DNI/NIE",
                          }}
                          fullWidth
                          allowZoom
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={8} alignItems="center">
                    <Grid item xs={12}>
                      <FormikTextField
                        name="N_CUENTA"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Número de Cuenta"
                            defaultMessage="Número de Cuenta"
                          />
                        }
                        fullWidth
                        margin="nomargin"
                        ibanformat
                        placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
                      />
                    </Grid>
                  </Grid>
                </StyledGroup>
              </Box>
              <Divider />
              <Box>
                <StyledGroup>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Idiomas"
                            defaultMessage="Idiomas"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        name="Otros_Idiomas"
                        isMulti
                        variant="compact"
                        margin="nomargin"
                        size="small"
                        closeMenuOnSelect={false}
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Choose",
                          defaultMessage: "Selecciona",
                        })}
                        options={formOptions.languageOptions}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikTextField
                        name="N_SS"
                        margin="nomargin"
                        disabled={employee.socialSecurityBlocked || false}
                        label={
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <FormattedMessage
                                id="CoordinatorFile.Número afiliación Seguridad Social"
                                defaultMessage="Número afiliación Seguridad Social"
                              />
                            </Grid>
                            {employee.socialSecurityBlocked && (
                              <Grid item>
                                <Tooltip
                                  title={formatMessage({
                                    id: "CoordinatorFile.El número no se puede cambiar porque se ha validado ya por otros contratos",
                                    defaultMessage:
                                      "El número no se puede cambiar porque se ha validado ya por otros contratos",
                                  })}
                                >
                                  <StyledCheckCircleIcon />
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        name="Nivel_formativo"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Nivel de educación"
                            defaultMessage="Nivel de educación"
                          />
                        }
                        required
                        variant="compact"
                        closeMenuOnSelect={true}
                        margin="nomargin"
                        size="small"
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Selecciona una opción",
                          defaultMessage: "Selecciona una opción",
                        })}
                        options={formOptions.educationOptions}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        name="Titulaci_n_oficial_SAD"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.¿Dispones de alguna de las siguientes titulaciones oficiales?"
                            defaultMessage="¿Dispones de alguna de las siguientes titulaciones oficiales?"
                          />
                        }
                        variant="compact"
                        closeMenuOnSelect={true}
                        options={formOptions.degreeOptions}
                        margin="nomargin"
                        size="small"
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Selecciona",
                          defaultMessage: "Selecciona",
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        name="A_os_de_experiencia"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Años de experiencia"
                            defaultMessage="Años de experiencia"
                          />
                        }
                        variant="compact"
                        closeMenuOnSelect={true}
                        margin="nomargin"
                        size="small"
                        placeholder={formatMessage({
                          id: "CoordinatorFile.Selecciona una opción",
                          defaultMessage: "Selecciona una opción",
                        })}
                        options={formOptions.experienceYearsOptions}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={4}>
                      <FormikRadioGroup
                        name="Sexo"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Sexo"
                            defaultMessage="Sexo"
                          />
                        }
                        size="small"
                        variant="compact"
                        options={[
                          {
                            label: (
                              <FormattedMessage
                                id="Hombre"
                                defaultMessage="Hombre"
                              />
                            ),
                            value: "Hombre",
                            icon: ManIcon,
                            checkedIcon: ManIcon,
                          },
                          {
                            label: (
                              <FormattedMessage
                                id="Mujer"
                                defaultMessage="Mujer"
                              />
                            ),
                            value: "Mujer",
                            icon: WomanIcon,
                            checkedIcon: WomanIcon,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormikRadioGroup
                        name="Carnet_de_conducir"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.DrivingLicense"
                            defaultMessage="Permiso de conducir"
                          />
                        }
                        size="small"
                        variant="compact"
                        options={[
                          {
                            label: (
                              <FormattedMessage id="Si" defaultMessage="Si" />
                            ),
                            value: "SI",
                            icon: SiIcon,
                            checkedIcon: SiIcon,
                          },
                          {
                            label: (
                              <FormattedMessage id="No" defaultMessage="No" />
                            ),
                            value: "NO",
                            icon: NoIcon,
                            checkedIcon: NoIcon,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormikRadioGroup
                        name="Veh_culo_propio"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.OwnsCar"
                            defaultMessage="¿Tienes coche propio?"
                          />
                        }
                        size="small"
                        variant="compact"
                        options={[
                          {
                            label: (
                              <FormattedMessage id="Si" defaultMessage="Si" />
                            ),
                            value: "Si",
                            icon: SiIcon,
                            checkedIcon: SiIcon,
                          },
                          {
                            label: (
                              <FormattedMessage id="No" defaultMessage="No" />
                            ),
                            value: "No",
                            icon: NoIcon,
                            checkedIcon: NoIcon,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="W_Breve_descripci_n"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.Breve descripción"
                            defaultMessage="Breve descripción"
                          />
                        }
                        labelSuffix={<VisibleFieldOnProfile />}
                        fullWidth
                        multiline
                        maxRows="8"
                        minRows="3"
                      />
                    </Grid>
                  </Grid>
                </StyledGroup>
                <Box marginTop={10} marginBottom={10}>
                  <FormikTextField
                    name="Observaciones_generales"
                    label={
                      <FormattedMessage
                        id="CoordinatorFile.Observaciones"
                        defaultMessage="Observaciones"
                      />
                    }
                    fullWidth
                    multiline
                    maxRows="8"
                    minRows="3"
                    disabled
                  />
                </Box>
                <Box marginTop={10} marginBottom={10}>
                  <NotesV2Table
                    parentId={employee.id}
                    moduleName="Candidatos"
                  />
                </Box>
                <StyledGroup>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: `1px solid ${cuideoTheme.palette.grey[300]}`,
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StyledSectionHeader
                      variant="h4"
                      style={{ borderBottom: 0, padding: 0, margin: 0 }}
                    >
                      <FormattedMessage
                        id="CoordinatorFile.Disponibilidad"
                        defaultMessage="Disponibilidad"
                      />
                    </StyledSectionHeader>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={displayWorkingUntil}
                          onChange={(_, checked) => {
                            setDisplayWorkingUntil(checked);
                            setFieldValue(
                              "Trabajando_Hasta",
                              checked ? null : undefined
                            );
                          }}
                          size="small"
                        />
                      }
                      label={<StyledLabel>¿Está trabajando?</StyledLabel>}
                      labelPlacement="start"
                    />
                    {displayWorkingUntil && (
                      <FormControlLabel
                        control={
                          <FormikDatePicker
                            disableToolbar
                            name="Trabajando_Hasta"
                            margin="normalX"
                            size="small"
                            fullWidth={false}
                            disablePast
                          />
                        }
                        label={<StyledLabel>¿Hasta cuando?</StyledLabel>}
                        labelPlacement="start"
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formikContext.values.substitutions}
                          onChange={(_, checked) => {
                            setFieldValue("substitutions", checked);
                          }}
                          size="small"
                        />
                      }
                      label={<StyledLabel>¿Suplencias?</StyledLabel>}
                      labelPlacement="start"
                    />
                  </Box>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <StyledField>
                        <StyledLabel>
                          <FormattedMessage
                            id="CoordinatorFile.Tipología de trabajo"
                            defaultMessage="Tipología de trabajo"
                          />
                        </StyledLabel>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="JobType.Externa entre semana"
                                  defaultMessage="Externa entre semana"
                                />
                              }
                              variant="compact"
                              name="Externa_entre_semana"
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="JobType.Externa fin de semana"
                                  defaultMessage="Externa fin de semana"
                                />
                              }
                              name="Externa_fin_de_semana"
                              variant="compact"
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="JobType.Interna entre semana"
                                  defaultMessage="Interna entre semana"
                                />
                              }
                              name="Interna_entre_semana"
                              variant="compact"
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="JobType.Interna fin de semana"
                                  defaultMessage="Interna fin de semana"
                                />
                              }
                              name="Interna_fin_de_semana"
                              variant="compact"
                            />
                          </Grid>
                        </Grid>
                      </StyledField>
                      {showTimeSlotsOptions && (
                        <StyledField
                          style={{ marginTop: "12px", marginBottom: "12px" }}
                        >
                          <StyledLabel>
                            <FormattedMessage
                              id="CoordinatorFile.Franja horaria"
                              defaultMessage="Franja horaria"
                            />
                          </StyledLabel>
                          <Grid container spacing={4}>
                            {timeSlotOptions.map((item: any) => {
                              return (
                                <Grid item xs={4} key={item.value}>
                                  <FormikCheckbox
                                    label={item.label}
                                    name={"timeSlot__" + item.value}
                                    variant="compact"
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </StyledField>
                      )}
                      <StyledField>
                        <StyledLabel>
                          <FormattedMessage
                            id="CoordinatorFile.Régimen de trabajo"
                            defaultMessage="Régimen de trabajo"
                          />
                        </StyledLabel>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="CoordinatorFile.Régimen Especial de Empleados del Hogar (REEH)"
                                  defaultMessage="Régimen Especial de Empleados del Hogar (REEH)"
                                />
                              }
                              variant="compact"
                              name="REEH"
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormikCheckbox
                              label={
                                <FormattedMessage
                                  id="CoordinatorFile.Régimen General (SAD)"
                                  defaultMessage="Régimen General (SAD)"
                                />
                              }
                              name="SAD"
                              variant="compact"
                            />
                          </Grid>
                        </Grid>
                      </StyledField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="Notas_disponibilidad"
                        label={
                          <FormattedMessage
                            id="CoordinatorFile.¿Quieres añadir algún comentario más en relación a tu disponibilidad?"
                            defaultMessage="¿Quieres añadir algún comentario más en relación a tu disponibilidad?"
                          />
                        }
                        fullWidth
                        multiline
                        maxRows="8"
                        minRows="3"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <StyledField>
                        <StyledLabel>
                          <FormattedMessage
                            id="CoordinatorFile.Tipo de tarea que puedes realizar"
                            defaultMessage="Tipo de tarea que puedes realizar"
                          />{" "}
                          <VisibleFieldOnProfile />
                        </StyledLabel>
                        <Grid container spacing={4}>
                          {formOptions.taskOptions.map((item: any) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={3}
                                key={item.value}
                                className="CMuiCoordinatorFileTask"
                              >
                                <FormikCheckbox
                                  label={
                                    <FormattedTaskField task={item.value} />
                                  }
                                  name={"tarea__" + item.value}
                                  variant="compact"
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </StyledField>
                    </Grid>
                  </Grid>
                </StyledGroup>
                <StyledGroup>
                  <ExperiencesTable
                    candidato={employee}
                    Experiencia_y_referencias={
                      formikContext.values.Experiencia_y_referencias
                    }
                    onChange={(data: any) => {
                      handlePartialFormUpdate(
                        "Experiencia_y_referencias",
                        data
                      );
                    }}
                  />
                  <Box marginTop={8} marginBottom={10}>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <StyledField>
                          <StyledLabel>
                            <FormattedMessage
                              id="CoordinatorFile.PathologyTypes"
                              defaultMessage="Patologías en las que tiene experiencia"
                            />
                          </StyledLabel>
                          <Grid container spacing={4}>
                            {formOptions.pathologyOptions.map((item: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  key={item.value}
                                  className="CMuiCoordinatorFilePathology"
                                >
                                  <FormikCheckbox
                                    label={
                                      <FormattedPathologyField
                                        pathology={item.value}
                                      />
                                    }
                                    name={"pathology__" + item.value}
                                    variant="compact"
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </StyledField>
                      </Grid>
                    </Grid>
                    <StyledHelper>
                      <FormattedMessage
                        id="CoordinatorFile.PathologyHelper"
                        defaultMessage="Las patologías que aparecen en la tabla de experiencias se añaden automáticamente en las patologías en las que el employee tiene experiencia"
                      />
                    </StyledHelper>
                  </Box>
                </StyledGroup>
                <FormikCVUpload
                  label={
                    <FormattedMessage
                      id="CoordinatorFile.CVLabel"
                      defaultMessage="Curriculum"
                    />
                  }
                  name="curriculum"
                  fullWidth
                  margin="normal"
                  size="medium"
                  changeLabel={
                    <FormattedMessage
                      id="InterestDataPage.ChangeCv"
                      defaultMessage="Cambiar"
                    />
                  }
                  uploadLabel={
                    <FormattedMessage
                      id="InterestDataPage.UploadCv"
                      defaultMessage="Subir"
                    />
                  }
                  showBigThumb
                />

                <StyledGroup>
                  <FormationsTable
                    Formaciones={formikContext.values.Formaciones}
                    onChange={(data: any) => {
                      handlePartialFormUpdate("Formaciones", data);
                    }}
                  />
                </StyledGroup>
              </Box>
              <Box mt={10} mb={10}>
                <UserInfoBox employee={employee} />
              </Box>
            </motion.div>
          </StyledWrapper>
        </>
      </MuiPickersUtilsProvider>
    </Form>
  );
};

export default CoordinatorFileInnerForm;
