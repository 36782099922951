import React, { Fragment, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { setCommonOptions } from "../actions/Common";
import useFormatters from "../components/FormattedFields/useFormatters";
import education from "../util/defaults/education";
import opcionesIdiomas from "../util/defaults/idiomas";
import paises from "../util/defaults/paises";
import patologias from "../util/defaults/patologias";
import provincias from "../util/defaults/provincias";
import tareas from "../util/defaults/tareas";
import opcionesTitulaciones from "../util/defaults/titulaciones";
import experienceYears from "../util/defaults/yearsOfExperience";
import { EMPLOYEE_TIME_SLOT } from "../api/employee/employee.types";
import {
  CONTRACT_OVERNIGHT_HOURS,
  CONTRACT_PRESENCE_HOURS,
  CONTRACT_SALARY_TYPE,
  CONTRACT_TYPE,
} from "../api/contract/contract.types";

interface ILangWrapperProps {
  languageCode: string;
  provinciasOptions: any;
  setCommonOptions: any;
  children: React.ReactNode;
}

// Small component to prepare common language texts
const LangWrapper = (props: ILangWrapperProps) => {
  const { setCommonOptions, languageCode } = props;
  const { formatMessage } = useIntl();
  const {
    formatCountry,
    formatLanguage,
    formatEducativeLevel,
    formatExperience,
    formatTimeSlots,
    formatContractType,
    formatContractOvernightHours,
    formatContractPresencetHours,
    formatContractSalaryType,
  } = useFormatters();

  useEffect(() => {
    const newPaises: any[] = [];
    // eslint-disable-next-line
    paises.map((item: any) => {
      newPaises.push({
        value: item.name,
        // label: formatMessage({ id: item.name }),
        label: formatCountry(item.name),
      });
    });

    const newMeses = [
      {
        value: 1,
        label: formatMessage({ id: "month.1", defaultMessage: "Enero" }),
      },
      {
        value: 2,
        label: formatMessage({ id: "month.2", defaultMessage: "Febrero" }),
      },
      {
        value: 3,
        label: formatMessage({ id: "month.3", defaultMessage: "Marzo" }),
      },
      {
        value: 4,
        label: formatMessage({ id: "month.4", defaultMessage: "Abril" }),
      },
      {
        value: 5,
        label: formatMessage({ id: "month.5", defaultMessage: "Mayo" }),
      },
      {
        value: 6,
        label: formatMessage({ id: "month.6", defaultMessage: "Junio" }),
      },
      {
        value: 7,
        label: formatMessage({ id: "month.7", defaultMessage: "Julio" }),
      },
      {
        value: 8,
        label: formatMessage({ id: "month.8", defaultMessage: "Agosto" }),
      },
      {
        value: 9,
        label: formatMessage({ id: "month.9", defaultMessage: "Septiembre" }),
      },
      {
        value: 10,
        label: formatMessage({ id: "month.10", defaultMessage: "Octubre" }),
      },
      {
        value: 11,
        label: formatMessage({ id: "month.11", defaultMessage: "Noviembre" }),
      },
      {
        value: 12,
        label: formatMessage({ id: "month.12", defaultMessage: "Diciembre" }),
      },
    ];

    const newProvincias: any[] = [];
    // eslint-disable-next-line
    provincias.map((item: any) => {
      if (item.businessCountry === "es") {
        newProvincias.push({
          value: item.value,
          label: item.value,
        });
      }
    });

    const newDias = [...Array(31)].map((x, i) => ({
      value: i + 1,
      label: i + 1,
    }));

    // Create an array of years starting from the current year minus 18,
    // ensuring the list always includes years for people who are at least 18 years old up to
    // a maximum of 65 years old
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 18;
    const newAnyos = [...Array(65)].map((x, i) => ({
      value: startYear - i,
      label: startYear - i,
    }));

    const newIdiomas: any[] = [];
    // eslint-disable-next-line
    opcionesIdiomas.map((item: any) => {
      newIdiomas.push({
        value: item,
        label: formatLanguage(item),
      });
    });

    const newTitulaciones: any[] = [];
    // eslint-disable-next-line
    opcionesTitulaciones.map((item: any) => {
      if (item.businessCountry === "es") {
        newTitulaciones.push({
          value: item.value,
          // label: formatMessage({ id: item.label }),
          label: item.label,
        });
      }
    });

    const newTareas: any[] = [];
    // eslint-disable-next-line
    tareas.map((item: any) => {
      if (item.businessCountry === "es") {
        newTareas.push({
          value: item.value,
          label: item.label,
        });
      }
    });

    const nivelEducativo: any[] = [];
    // eslint-disable-next-line
    education.map((item: any) => {
      nivelEducativo.push({
        value: item.name,
        // label: formatMessage({ id: item.label }),
        label: formatEducativeLevel(item.label),
      });
    });

    const newPatologias: any[] = [];
    // eslint-disable-next-line
    patologias.map((item: any) => {
      if (item.businessCountry === "es") {
        newPatologias.push({
          value: item.value,
          label: item.label,
        });
      }
    });

    const newExpYears: any[] = [];
    experienceYears.forEach((item: any) => {
      newExpYears.push({
        value: item.name,
        // label: formatMessage({ id: `expYears.${item.name}` }),
        label: formatExperience(item.name),
      });
    });

    const newTimeSlotOptions: any[] = [
      {
        value: EMPLOYEE_TIME_SLOT.MORNING,
        label: formatTimeSlots(EMPLOYEE_TIME_SLOT.MORNING),
        breaklineLabel: formatTimeSlots(EMPLOYEE_TIME_SLOT.MORNING, true),
      },
      {
        value: EMPLOYEE_TIME_SLOT.NOON,
        label: formatTimeSlots(EMPLOYEE_TIME_SLOT.NOON),
        breaklineLabel: formatTimeSlots(EMPLOYEE_TIME_SLOT.NOON, true),
      },
      {
        value: EMPLOYEE_TIME_SLOT.AFTERNOON,
        label: formatTimeSlots(EMPLOYEE_TIME_SLOT.AFTERNOON),
        breaklineLabel: formatTimeSlots(EMPLOYEE_TIME_SLOT.AFTERNOON, true),
      },
    ];

    const newContractTypeOptions: any[] = [
      {
        value: CONTRACT_TYPE.FULL_INDEFINITE,
        label: formatContractType(CONTRACT_TYPE.FULL_INDEFINITE),
      },
      {
        value: CONTRACT_TYPE.PARTIAL_INDEFINITE,
        label: formatContractType(CONTRACT_TYPE.PARTIAL_INDEFINITE),
      },
      {
        value: CONTRACT_TYPE.FULL_INTERIM,
        label: formatContractType(CONTRACT_TYPE.FULL_INTERIM),
      },
      {
        value: CONTRACT_TYPE.PARTIAL_INTERIM,
        label: formatContractType(CONTRACT_TYPE.PARTIAL_INTERIM),
      },
      {
        value: CONTRACT_TYPE.FULL_EVENTUAL,
        label: formatContractType(CONTRACT_TYPE.FULL_EVENTUAL),
      },
      {
        value: CONTRACT_TYPE.PARTIAL_EVENTUAL,
        label: formatContractType(CONTRACT_TYPE.PARTIAL_EVENTUAL),
      },
    ];

    const newContractOvernightHoursOptions: any[] = [
      {
        value: CONTRACT_OVERNIGHT_HOURS.YES,
        label: formatContractOvernightHours(CONTRACT_OVERNIGHT_HOURS.YES),
      },
      {
        value: CONTRACT_OVERNIGHT_HOURS.NO,
        label: formatContractOvernightHours(CONTRACT_OVERNIGHT_HOURS.NO),
      },
    ];

    const newContractPresenceHoursOptions: any[] = [
      {
        value: CONTRACT_PRESENCE_HOURS.YES,
        label: formatContractPresencetHours(CONTRACT_PRESENCE_HOURS.YES),
      },
      {
        value: CONTRACT_PRESENCE_HOURS.NO,
        label: formatContractPresencetHours(CONTRACT_PRESENCE_HOURS.NO),
      },
    ];

    const newContractSalaryTypeOptions: any[] = [
      {
        value: CONTRACT_SALARY_TYPE.DAILY,
        label: formatContractSalaryType(CONTRACT_SALARY_TYPE.DAILY),
      },
      {
        value: CONTRACT_SALARY_TYPE.MONTHLY,
        label: formatContractSalaryType(CONTRACT_SALARY_TYPE.MONTHLY),
      },
    ];

    setCommonOptions({
      paisesOptions: newPaises,
      provinciasOptions: newProvincias,
      diasOptions: newDias,
      mesesOptions: newMeses,
      anyosOptions: newAnyos,
      idiomasOptions: newIdiomas,
      titulacionesOptions: newTitulaciones,
      tareasOptions: newTareas,
      educacionOptions: nivelEducativo,
      patologiasOptions: newPatologias,
      experienceYearsOptions: newExpYears,
      timeSlotOptions: newTimeSlotOptions,
      contractTypeOptions: newContractTypeOptions,
      contractOvernightHoursOptions: newContractOvernightHoursOptions,
      contractPresenceHoursOptions: newContractPresenceHoursOptions,
      contractSalaryTypeOptions: newContractSalaryTypeOptions,
    });
    // eslint-disable-next-line
  }, [languageCode]);
  return <Fragment>{props.children}</Fragment>;
};

const mapStateToProps = ({ commonData }: { commonData: any }) => {
  const {
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    patologiasOptions,
    timeSlotOptions,
    contractTypeOptions,
    contractOvernightHoursOptions,
    contractPresenceHoursOptions,
    contractSalaryTypeOptions,
  } = commonData;

  return {
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    patologiasOptions,
    timeSlotOptions,
    contractTypeOptions,
    contractOvernightHoursOptions,
    contractPresenceHoursOptions,
    contractSalaryTypeOptions,
  };
};

export default connect(mapStateToProps, { setCommonOptions })(LangWrapper);
