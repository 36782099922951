import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import BadgeIcon from "../../../../../../assets/images/iconos/badge.png";
import {
  StyledTriDataWrapper,
  StyledTriIcon,
  StyledTriItem,
  StyledTriOwnerData,
  StyledTriOwnerLabel,
} from "./ContractCardSC";
import ContractOwnerSelect from "./ContractOwnerSelect";
import { useSelector } from "react-redux";
import { TAuthenticatedUser } from "../../../../../../reducers/Auth";
import ContractServiceOwnerSelect from "./ContractServiceOwnerSelect";
import { authUserHasRole } from "../../../../../../reducers/hooks/useAuthUser";
import { USER_ROLES } from "../../../../../../api/security";

interface IContractOwnershipProps {
  contractOwnerId?: string;
  contractOwnerName?: string;
  contractCrmIdName?: string;
  serviceOwnerName?: string;
  serviceName?: string;
  onChangeContractOwner: (newOwnerId: string, newOwnerName: string) => void;
  onChangeContractServiceOwner: (newOwnerId: string, newOwnerName: string) => void;
}

const ContractOwnership: React.FC<IContractOwnershipProps> = (props) => {
  const {
    contractOwnerId,
    contractOwnerName,
    contractCrmIdName,
    serviceOwnerName,
    serviceName,
    onChangeContractOwner,
    onChangeContractServiceOwner
  } = props;
  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );

  const [contractServiceOwnerName, setContractServiceOwnerName] = useState(serviceOwnerName);

  const handleChangeContractServiceOwner = (newOwnerId: string, newOwnerName: string) => {
    setContractServiceOwnerName(newOwnerName);
    onChangeContractServiceOwner(newOwnerId, newOwnerName);
  };

  return (
    <>
      <StyledTriItem>
        <StyledTriIcon src={BadgeIcon} />
        <StyledTriDataWrapper>
          <ContractOwnerSelect
            disabled={authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER)}
            contractOwnerId={contractOwnerId ?? ""}
            contractOwnerName={contractOwnerName ?? ""}
            contractCrmIdName={contractCrmIdName}
            onChangeContractOwner={onChangeContractOwner}
          />
          {!!contractServiceOwnerName && contractServiceOwnerName !== contractOwnerName && (
            <>
              {authUser && (authUser.userRoles.includes('ROLE_MANAGER') || authUser.userRoles.includes('ROLE_RECRUIT_MANAGER')) ?
              (
                <>
                  <StyledTriDataWrapper>
                    <ContractServiceOwnerSelect
                      serviceOwnerName={contractServiceOwnerName ?? ""}
                      serviceName={serviceName ?? ""}
                      onChangeContractServiceOwner={handleChangeContractServiceOwner}
                    />
                  </StyledTriDataWrapper>
                </>
              ) : (
                <>
                  <StyledTriOwnerLabel>
                    <FormattedMessage
                      id="ContractCard.Propietario servicio"
                      defaultMessage="Propietario servicio"
                    />
                  </StyledTriOwnerLabel>
                  <StyledTriOwnerData>{contractServiceOwnerName}</StyledTriOwnerData>
                </>
              )}
            </>
          )}
        </StyledTriDataWrapper>
      </StyledTriItem>
    </>
  );
};

export default ContractOwnership;
