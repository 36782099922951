import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledExpansionPanel = styled(Accordion)`
  background-color: transparent;
  border: 1px solid ${cuideoTheme.palette.primary.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
`;

const StyledExpansionPanelSummary = styled(AccordionSummary)`
  min-height: 3rem;
  &.Mui-expanded {
    min-height: 3rem;
  }
  .MuiAccordionSummary-content {
    margin: 0.5rem 0;
  }
`;

const StyledSummaryTypography = styled(Typography)`
  text-transform: uppercase;
  font-size: 0.625rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 0.625rem;
  opacity: 0.75;
  line-height: 1.2;
`;

const LeerInfoLegal = () => {
  return (
    <StyledExpansionPanel elevation={0}>
      <StyledExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <StyledSummaryTypography color="primary">
          <FormattedMessage
            id="Legal.Title"
            defaultMessage="Información Básica sobre Protección de Datos"
          />
        </StyledSummaryTypography>
      </StyledExpansionPanelSummary>
      <AccordionDetails>
        <StyledTypography color="primary">
          <FormattedMessage
            id="Legal.Line1"
            defaultMessage="En cumplimiento con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 (Reglamento General de Protección de Datos, RGPD) y la Ley Orgánica 3/2018, le informamos que los datos personales que usted nos facilite, incluidos el certificado de antecedentes penales, DNI y cualquier otro documento necesario para el proceso de selección, serán tratados de forma confidencial por CUIDA CARE MARKETING, S.L. (CUIDEO). La finalidad de este tratamiento es gestionar su candidatura, verificar el cumplimiento de requisitos legales y garantizar la idoneidad para el puesto solicitado. Al aceptar, usted brinda su consentimiento expreso para que tratemos estos datos conforme a las normativas vigentes. Puede revocar su consentimiento en cualquier momento y ejercer sus derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición mediante solicitud escrita a dpd@cuideo.com o dirigiéndose a C/ Conxita Supervia, 5 – 08028, Barcelona"
          />
        </StyledTypography>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export default LeerInfoLegal;
