import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Select, { OnChangeValue, StylesConfig } from "react-select";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { useCoordinationStaffOptions } from "../../../../../../reducers/hooks/useCoordinationStaffOptions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import SadWorkers from "../../../../../../assets/images/sad_workers.svg";
import { TSelectOption } from "../../../../pages/ServicePage/components/ServiceValuation/types";

const StyledButton = styled(Button)`
  text-transform: none;
  height: 1.75rem;
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 600;
  padding: 0 0.375rem;

  .MuiButton-label {
    justify-content: space-between;
  }

  .CMuiButtonLabelTop {
    color: ${cuideoTheme.palette.primary.main};
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .CMuiButtonLabelBottom {
    color: ${cuideoTheme.palette.primary.main};
    font-size: 1rem;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(0, 72, 120, 0.2);
    box-shadow: none;
  }

  &.CMui-active {
    background-color: ${cuideoTheme.palette.primary.main};
    color: ${cuideoTheme.palette.primary.contrastText};

    .CMuiButtonLabelTop {
      color: ${cuideoTheme.palette.primary.contrastText};
    }

    .CMuiButtonLabelBottom {
      color: ${cuideoTheme.palette.primary.contrastText};
    }
  }

  &.Mui-disabled {
    pointer-events: none;
    background-color: rgba(0, 72, 120, 0.05);
    color: ${cuideoTheme.palette.primary.light};
    opacity: 0.6;

    &:hover {
      background-color: rgba(0, 72, 120, 0.05); /* mismo color que base */
      box-shadow: none;
    }
  }
`;

const StyledButtonLabel = styled.span`
  display: block;
  text-align: left;
`;

const StyledButtonLabelTop = styled.span`
  display: block;
  margin: 0 0 -0.125rem 0;
  line-height: 1;
`;

const StyledButtonLabelBottom = styled.span`
  display: block;
  margin: 0;
  line-height: 1.1;

  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDialog = styled(Dialog)``;

const StyledContentWithImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const StyledImage = styled.img`
  height: 6rem;
  width: auto;
`;

const StyledText = styled(Typography)`
  font-size: 0.9375rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
`;

const StyledRight = styled.div``;

const StyledConfirmButton = styled(Button)`
  width: 7.25rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

const selectStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
    fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
    fontSize: "0.9375rem",
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  option: (provided) => ({
    ...provided,
    fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
    fontSize: "0.9375rem",
  }),
};

interface IContractOwnerSelectProps {
  contractOwnerId: string;
  contractOwnerName: string;
  contractCrmIdName?: string;
  onChangeContractOwner: (newOwnerId: string, newOwnerName: string) => void;
  disabled?: boolean;
}

const ContractOwnerSelect: React.FC<IContractOwnerSelectProps> = (props) => {
  const {
    contractOwnerId,
    contractOwnerName,
    contractCrmIdName,
    onChangeContractOwner,
    disabled,
  } = props;
  const [state, setState] = useState({
    isOpen: false,
    value: undefined as undefined | TSelectOption,
    dialogOpen: false,
    inProcess: false,
  });

  const coordinatorOptions = useCoordinationStaffOptions();
  const selectedCoordinator = useMemo(() => {
    for (let i = 0; i < coordinatorOptions.length; i++) {
      const found = coordinatorOptions[i].options.find(
        (option) => option?.value === contractOwnerId
      );
      if (found) {
        return found;
      }
    }

    return null;
  }, [contractOwnerId, coordinatorOptions]);

  const handleToggleOpen = () => {


    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const handleSelectChange = (value: OnChangeValue<any, false>) => {
    setState((prevState) => ({
      ...prevState,
      value: value as TSelectOption,
      dialogOpen: true,
      isOpen: false,
    }));
  };

  const handleConfirm = () => {
    if (!state.value) {
      return;
    }

    onChangeContractOwner(state.value.value, state.value.label);
    setState((prevState) => ({
      ...prevState,
      value: undefined,
      dialogOpen: false,
      isOpen: false,
    }));
  };

  const handleCancel = () => {
    setState((prevState) => ({ ...prevState, dialogOpen: false }));
  };

  return (
    <>
      <Dropdown
        isOpen={state.isOpen}
        onClose={handleToggleOpen}
        target={
          <StyledButton
            onClick={handleToggleOpen}
            color="primary"
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            fullWidth
            disabled={disabled}
            className={classNames({
              CMuiContractOwnerSelect: true,
              "CMui-active": !!state.isOpen,
            })}
          >
            <StyledButtonLabel className="CMuiButtonLabel">
              <StyledButtonLabelTop className="CMuiButtonLabelTop">
                <FormattedMessage
                  id="ContractCard.Propietario contrato"
                  defaultMessage="Propietario contrato"
                />
              </StyledButtonLabelTop>
              <StyledButtonLabelBottom className="CMuiButtonLabelBottom">
                {contractOwnerName}
              </StyledButtonLabelBottom>
            </StyledButtonLabel>
          </StyledButton>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={handleSelectChange}
          options={coordinatorOptions}
          tabSelectsValue={false}
          styles={selectStyles}
          placeholder={"Reasignar Contrato a..."}
          value={selectedCoordinator}
        />
      </Dropdown>
      <StyledDialog open={state.dialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="ContractOwnerSelect.Reasignar contrato"
            defaultMessage="Reasignar contrato"
          />
        </DialogTitle>
        <StyledContentWithImage>
          <StyledImage src={SadWorkers} />
          <StyledRight>
            <DialogContent>
              <StyledText>
                <FormattedMessage
                  id="ContractOwnerSelect.Vas a reasignar el {contractName} a {newOwnerName}"
                  defaultMessage="Vas a reasignar el {contractName} a {newOwnerName}"
                  values={{
                    contractName: <b>{contractCrmIdName ?? "Contrato"}</b>,
                    newOwnerName: <b>{state.value?.label ?? ""}</b>,
                  }}
                />
              </StyledText>
              <StyledText>
                <FormattedMessage
                  id="ContractOwnerSelect.¿Seguro que quieres continuar?"
                  defaultMessage="¿Seguro que quieres continuar?"
                />
              </StyledText>
            </DialogContent>
          </StyledRight>
        </StyledContentWithImage>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            disabled={state.inProcess}
          >
            <FormattedMessage
              id="ContractOwnerSelect.Cancelar"
              defaultMessage="Cancelar"
            />
          </Button>
          <StyledConfirmButton
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={state.inProcess}
          >
            {state.inProcess ? (
              <CircularProgress size={19} thickness={5} />
            ) : (
              <FormattedMessage
                id="ContractOwnerSelect.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

// Sub-components
const StyledMenu = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 11px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 2;
  margin-top: 0;
  width: 15rem;
  right: 0;
`;

const StyledBlanket = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`;

interface IDropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}

const Dropdown: FunctionComponent<IDropdownProps> = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <div style={{ position: "relative", minWidth: "9rem" }}>
    {target}
    {isOpen ? <StyledMenu>{children}</StyledMenu> : null}
    {isOpen ? <StyledBlanket onClick={onClose} /> : null}
  </div>
);

export default ContractOwnerSelect;
